/* Global Styles */
* , *::before , *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter" !important;
    font-weight: normal;
    font-style: normal;
}

:root {
    --bg-dark: #141414;
    --bg-purple: #25bb92;
    --bg-line: #262626;
}

.bg-main-dark {
    background-color: var(--bg-dark) !important;
}

.bg-main-purple {
    background-color: var(--bg-purple) !important;
}

.polygon-image {
    -webkit-clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    width: 58px;
    height: 64px;
    object-fit: cover;
    object-position: center;
}

/* toastify custom style */

.Toastify__toast {
    background-color: var(--bg-line) !important;
    border-radius: 8px !important;
}

.Toastify__toast-icon {
    display: none !important;
}

.Toastify__toast-container {
    font-size: 14px !important;
    padding: 4px !important;
}

.tox-notifications-container {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}