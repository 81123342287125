@font-face {
    font-family: "Avenir Next";
    font-weight: normal;
    src: url(../fonts/avenir/AvenirNext-Regular-08.ttf);
}

@font-face {
    font-family: "Avenir Next";
    font-weight: 300;
    src: url(../fonts/avenir/AvenirNext-UltraLight-11.ttf);
}

@font-face {
    font-family: "Avenir Next";
    font-weight: 500;
    src: url(../fonts/avenir/AvenirNext-Medium-06.ttf);
}

@font-face {
    font-family: "Avenir Next";
    font-weight: bold;
    src: url(../fonts/avenir/AvenirNext-Bold-01.ttf);
}

@font-face {
    font-family: "Avenir Next";
    font-weight: 600;
    src: url(../fonts/avenir/AvenirNext-DemiBold-03.ttf);
}

@font-face {
    font-family: "Avenir Next";
    font-weight: 700;
    font-style: italic;
    src: url(../fonts/avenir/AvenirNext-HeavyItalic-10.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 100;
    src: url(../fonts/inter/Inter-Thin.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 200;
    src: url(../fonts/inter/Inter-ExtraLight.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 300;
    src: url(../fonts/inter/Inter-Light.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 400;
    src: url(../fonts/inter/Inter-Regular.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 500;
    src: url(../fonts/inter/Inter-Medium.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 600;
    src: url(../fonts/inter/Inter-SemiBold.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 700;
    src: url(../fonts/inter/Inter-Bold.ttf);
}
@font-face {
    font-family: "Inter";
    font-weight: 800;
    src: url(../fonts/inter/Inter-ExtraBold.ttf);
}
@font-face {
    font-family: "Inter";
    font-weight: 900;
    src: url(../fonts/inter/Inter-Black.ttf);
}

::-webkit-scrollbar {
    width: 2px;
    background: #141414;
}

::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px black;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb {
    background: #25bb92;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: #25bb92;
}

* {
    box-sizing: border-box;
    font-family: "Inter";
    font-weight: normal;
    font-style: normal;
}

body {
    background-color: #141414 !important;
}

.tox-notifications-container {
    display: none;
}

.fp-watermark {
    display: none;
}
